<template>
	<div class="exam">
		<el-container class="container" v-if="$store.state.userInfo && $store.state.userInfo.role_id && $store.state.userInfo.role_id <= 2">
			<el-header class="header">
				<el-select v-model="form.type" placeholder="题目类型(默认全部)" clearable>
					<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
				</el-select>
				<el-select v-model="form.limit" placeholder="题目数量(默认10条)" clearable>
					<el-option :label="10" :value="10"></el-option>
					<el-option :label="20" :value="20"></el-option>
					<el-option :label="30" :value="30"></el-option>
					<el-option :label="40" :value="40"></el-option>
				</el-select>
				<span>过滤对题:<el-switch v-model="form.fliterRight" /></span>
				<el-button type="primary" size="small" @click="startExam" style="margin-left: 20px;">开始考试</el-button>
			</el-header>
			<el-main></el-main>
		</el-container>
		<el-empty description="您未登录或者权限不够" v-else class="empty"></el-empty>
		<ExamDialog ref="examDialog" @close="onClose" v-if="dialogShow"></ExamDialog>
	</div>
</template>
<script>
import ExamDialog from './ExamDialog';
export default {
	name: 'exam-page',
	components: {
		ExamDialog
	},
	data() {
		return {
			select: '',
			form: {},
			typeList: [],
			dialogShow: false
		};
	},
	created() {
		this.getTypeList();
	},
	methods: {
		getTypeList() {
			this.$http.get('/topic/typelist').then(res => {
				if (res.code === 200) {
					this.typeList = res.list;
				}
			});
		},
		startExam() {
			this.dialogShow = true;
			this.$nextTick(() => {
				this.$refs.examDialog.open(this.form);
			});
		},
		onClose() {
			this.dialogShow = false;
		}
	}
};
</script>

<style scoped lang="less">
.exam .container {
	height: calc(100vh - 200px);
	.header{
		&>*{
			margin: 0 10px;
		}
	}
	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		p {
			margin-right: 30px;
		}
	}
	.res {
		background-color: rgba(64, 157, 253, 0.1);
		padding: 20px;
		margin-top: 20px;
	}
}
</style>
