<template>
	<div class="home">
		<div class="top">
			<el-carousel class="carousel">
				<el-carousel-item v-for="item in bannerList" :key="item.id">
					<div class="item">
						<a :href="item.target" target="_blank"><img :src="item.url" alt="" /></a>
					</div>
				</el-carousel-item>
			</el-carousel>
			<div class="t-right">
				<el-card class="box-card">
					<div slot="header" class="clearfix header">
						<div class="left">
							<img src="~@/assets/image/wz.png" alt="" width="20" />
							<span>文章榜</span>
						</div>
						<el-button type="text">查看更多>></el-button>
					</div>
					<div class="content">
						<div class="c-box">
							<p v-for="o in 8" :key="o" class="text item">
								<strong>{{ o }}</strong>
								<span>{{ '列表内容列表内容列表内容列表内容列表内容 ' }}</span>
							</p>
						</div>
					</div>
				</el-card>
			</div>
		</div>
		<el-row :gutter="20" style="margin-top: 20px;" class="bottom">
			<el-col :span="8">
				<el-card>
					<div class="box">
						<el-image style="width: 100px; height: 100px" :src="img1" fit="cover" />
						<div class="right">
							<p>前端经典案例100道</p>
							<p>每天进步一点点,早日成为大佬级</p>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="8">
				<el-card>
					<div class="box">
						<el-image style="width: 100px; height: 100px" :src="img2" fit="cover" />
						<div class="right">
							<p>前端经典案例100道</p>
							<p>每天进步一点点,早日成为大佬级</p>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :span="8">
				<el-card>
					<div class="box">
						<el-image style="width: 100px; height: 100px" :src="img1" fit="cover" />
						<div class="right">
							<p>前端经典案例100道</p>
							<el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
								<p>每天进步一点点,早日成天进步一点点早日成天进步一点点早日成天进步一点点,早日成天进步一点点,早日成为大佬级</p>
							</el-tooltip>
						</div>
					</div>
				</el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import img1 from '@/assets/image/day1.jpeg';
import img2 from '@/assets/image/day2.jpg';
export default {
	name: 'home-page',
	data() {
		return {
			bannerList: [],
			img1,
			img2
		};
	},
	created() {
		this.getBannerList();
	},
	methods: {
		getBannerList() {
			this.$http.get('/banner').then(res => {
				
				if (res.code === 200) {
					this.bannerList = res.list;
				}
			});
		}
	}
};
</script>

<style scoped lang="less">
.home {
	.top {
		display: flex;
		.carousel {
			flex-shrink: 0;
			width: 800px;
			/deep/.el-carousel__container {
				height: 370px;
			}
			img {
				width: 100%;
			}
		}
		.t-right {
			flex-grow: 1;
			margin-left: 20px;
			.box-card {
				height: 100%;
				display: flex;
				flex-direction: column;
				.header {
					display: flex;
					justify-content: space-between;
					align-items: center;
					.left {
						display: flex;
						align-items: center;
						img {
							margin-right: 5px;
						}
					}
				}
				/deep/.el-card__body{
					flex-grow: 1;
				}
				.content {
					height: 100%;
					.c-box {
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: space-evenly;
						align-items: flex-start;
						p{
							display: inline-flex;
							align-items: center;
							strong{
								margin-right: 10px;
								color: #333;
							}
							span{
								font-size: 13px;
								color: #aaa;
							}
						}
						p:nth-child(1) {
							strong {
								background: linear-gradient(180deg, #f64242 20%, rgba(246, 66, 66, 0.4) 80%);
								-webkit-background-clip: text;
								background-clip: text;
								color: transparent;
							}
						}
						p:nth-child(2) {
							strong {
								background: linear-gradient(180deg, #ff7426 20%, rgba(255, 116, 38, 0.4) 80%);
								-webkit-background-clip: text;
								background-clip: text;
								color: transparent;
							}
						}
						p:nth-child(3) {
							strong {
								background: linear-gradient(180deg, #ffac0c 20%, rgba(255, 172, 12, 0.4) 80%);
								-webkit-background-clip: text;
								background-clip: text;
								color: transparent;
							}
						}
					}
				}
			}
		}
	}

	.bottom {
		.box {
			display: flex;
			/deep/.el-image {
				flex-shrink: 0;
			}
			.right {
				flex-grow: 1;
				margin-left: 10px;
				padding: 5px;
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				p:nth-child(1) {
					color: red;
					font-size: 18px;
					font-weight: bold;
				}
				p:nth-child(2) {
					color: #aaa;
					font-size: 14px;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					line-clamp: 3;
					-webkit-box-orient: vertical;
				}
			}
		}
	}
}
</style>
