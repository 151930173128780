import CryptoJS from 'crypto-js';
//DES加密
export const encryptBy = (message) => {
	var key = '12345678';

	function encryptByDES(message, key) {
		var keyHex = CryptoJS.enc.Utf8.parse(key)
		var option = {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		}
		var encrypted = CryptoJS.DES.encrypt(message, keyHex, option)
		return encrypted.ciphertext.toString()
	}
	return encryptByDES(message, key);
}
//DES解密

export const decryptBy = (message) => {
	var key = '12345678';
	//DES  ECB模式解密
	function decryptByDES(message, key) {
		var keyHex = CryptoJS.enc.Utf8.parse(key);
		var decrypted = CryptoJS.DES.decrypt({
			ciphertext: CryptoJS.enc.Hex.parse(message)
		}, keyHex, {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		});
		var result_value = decrypted.toString(CryptoJS.enc.Utf8);
		return result_value;
	}
	return decryptByDES(message, key);
}
// "数字/Number,字符串/String,布尔值/Boolean,空值/Null,未定义/Undefined,符号/Symbol, 对象/Object"
export const getScroe = (result, answer, keyword, keywordRadio) => {
	if (!result || !answer) {
		return 0;
	}
	let sroce1 = similar(result, answer) * (100 - keywordRadio)
	let arr = keyword.toLocaleLowerCase().split(',')
	let keyNum = 0

	for (let i = 0; i < arr.length; i++) {
		let newArr = arr[i].split('/')
		
		let bool = newArr.some(item => answer.includes(item))
		if (bool) {
			keyNum++
		}
	}
	
	let scroe2 = keywordRadio * keyNum / arr.length
	return scroe2 + sroce1 + ''
}

export const Cookies = {
	setItem: function(key, value, time) {
		document.cookie = `${key}=${value};expires=` + new Date(Date.now() + time * 24 * 60 * 60 * 1000)
			.toUTCString()
	},
	getItem: function(key) {
		if(!document.cookie){
			
			return ''
		}
		return this.getAllItem()[key]
	},
	removeItem: function(key) {
		this.setItem(key, '', -1)
	},
	getAllItem: function() {
		if(!document.cookie){
			
			return null
		}
		let arr = document.cookie.split('; ')
		let obj = {}
		
		for (let i = 0; i < arr.length; i++) {
			let newArr = arr[i].split('=')
			obj[newArr[0]] = newArr[1]
		}
		return obj
	},
	clear: function() {
		let obj = this.getAllItem()
		for (let key in obj) {
			this.removeItem(key)
		}
	}
}

function trimAll(ele) {
	if (typeof ele === 'string') {
		return ele.split(/[\t\r\f\n\s]*/g).join('').toLocaleLowerCase();
	}
}

function similar(s, t, f) {
	if (!s || !t) {
		return 0;
	}
	s = trimAll(s)
	t = trimAll(t)
	var l = s.length > t.length ? s.length : t.length;
	var n = s.length;
	var m = t.length;
	var d = [];
	f = f || 3;
	var min = function(a, b, c) {
		return a < b ? (a < c ? a : c) : b < c ? b : c;
	};
	var i, j, si, tj, cost;
	if (n === 0) return m;
	if (m === 0) return n;
	for (i = 0; i <= n; i++) {
		d[i] = [];
		d[i][0] = i;
	}
	for (j = 0; j <= m; j++) {
		d[0][j] = j;
	}
	for (i = 1; i <= n; i++) {
		si = s.charAt(i - 1);
		for (j = 1; j <= m; j++) {
			tj = t.charAt(j - 1);
			if (si === tj) {
				cost = 0;
			} else {
				cost = 1;
			}
			d[i][j] = min(d[i - 1][j] + 1, d[i][j - 1] + 1, d[i - 1][j - 1] + cost);
		}
	}
	let res = 1 - d[n][m] / l;
	let ret = parseFloat(res.toFixed(f))
	
	return ret;
}