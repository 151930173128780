<template>
	<el-dialog title="修改头像" :visible.sync="dialogVisible" width="50%" @close="onClose" class="head-dialog">
		<div class="img">
			<div class="item" v-for="item in imgList" :key="item.id" :class="item.id == on ? 'on' : ''" @click="choiceImg(item)">
				<img :src="item.url" alt="" />
				<div class="tip"><i class="el-icon-check"></i></div>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="dialogVisible = false">取 消</el-button>
			<el-button type="primary" @click="onsubmit">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	name: 'head-page',
	data() {
		return {
			dialogVisible: false,
			imgList: [],
			on: null,
			url: '',
		};
	},
	created() {
		
		this.getImgList();
	},
	computed:{
		userInfo:{
			get(){
				return this.$store.state.userInfo
			},
			set(val){
				this.$store.commit('setUserInfo',val)
			}
		}
	},
	methods: {
		open() {
			this.dialogVisible = true;
		},
		getImgList() {
			
			this.$http.get('/img/list').then(res => {
				
				this.imgList = res.list;
			});
		},
		onClose() {
			
			this.$emit('onclose');
		},
		choiceImg(item) {
			this.on = item.id;
			this.url = item.url;
		},
		onsubmit() {
			if (this.url) {
				

				this.$http
					.post('/users/head', {
						id: this.userInfo.id,
						head_photo: this.url
					})
					.then(res => {
						
						if (res.code === 200) {
							this.$message({
								message: '修改头像成功',
								type: 'success'
							});
							let obj = Object.assign({},this.userInfo)
							obj.head_photo = this.url
							this.userInfo = obj
							this.dialogVisible = false;
						}
					});
			} else {
				this.$message({
					message: '请选择图片',
					type: 'warning'
				});
			}
		}
	}
};
</script>

<style scoped lang="less">
.head-dialog {
	.img {
		display: flex;
		flex-wrap: wrap;
		.item {
			width: 50px;
			height: 50px;
			margin: 10px;
			position: relative;
			cursor: pointer;
			.tip {
				display: none;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background-color: rgba(64, 158, 253, 0.1);
				background-image: linear-gradient(to left top, rgba(64, 158, 253, 1) 25%, rgba(64, 158, 253, 0.1) 25%);
				border: 2px solid rgba(64, 158, 253, 1);
				box-sizing: border-box;
				i {
					color: #fff;
					position: absolute;
					right: 0;
					bottom: 0;
					transform: rotate(-10deg);
				}
			}
			img {
				width: 50px;
				height: 50px;
				object-fit: cover;
			}
			&.on .tip {
				display: block;
			}
		}
	}
}
</style>
