<!-- link -->
<template>
	<div class="msg-page">
		<el-container :style="{ height: boxHeight - 200 + 'px' }">
			<el-container>
				<el-header style="text-align: right; font-size: 12px;height: auto;">
					<div class="title">
						<h2>
							<strong>寄语</strong>
							:愿作明灯照前路，与君共勉探前端
						</h2>
						<h3>网站初创有什么问题或者BUG希望大家提供宝贵意见</h3>
					</div>
				</el-header>
				<el-main>
					<div class="mes-box">
						<div class="msg-send"><CommentEdit v-model="tinymce" :maxCount="500" @submit="submitEvent"></CommentEdit></div>
						<div class="msg-title">
							<span>留言({{ total }})</span>
							<div class="page">
								<el-link :underline="false" :disabled="currentPage === 1" type="success" @click="currentPage--">上一页</el-link>
								<el-link :underline="false" :disabled="currentPage === maxPage" type="success" @click="currentPage++">下一页</el-link>
							</div>
						</div>
						<div class="msg-content">
							<div class="msg-c-item" v-for="(item, index) in messageList" :key="item.id">
								<img :src="item.head_photo" alt="" />
								<div class="msg-c-main">
									<!-- 顶部 -->
									<div class="top">
										<div>
											<span class="name">{{ item.real_name }}</span>
											<span class="floor">第{{ total - (currentPage - 1) * 10 - index }}楼</span>
										</div>
										<!-- 留言内容 -->
										<p v-html="item.content"></p>
									</div>
									<!-- 留言下方 -->
									<div class="bottom">
										<span class="time">{{ moment(item.create_date).format('YYYY-MM-DD HH:mm:ss') }}</span>
										<p>
											<span class="reply" @click="toComment(index, item)">
												<i class="el-icon-chat-round"></i>
												回复
											</span>
											<span class="comment" @click="getComment(item)">
												<i class="el-icon-s-promotion"></i>
												评论{{ item.comment_count }}
											</span>
											<span class="like" :class="messageLikeIdList.includes(item.id) ? 'on' : ''" @click="messageLike(item)">
												<i class="el-icon-thumb"></i>
												点赞{{ item.like || 0 }}
											</span>
										</p>
									</div>
									<!-- 评论区域 -->
									<div class="comment" v-if="item.commentShow">
										<div v-for="item1 in item.comment_list" :key="item1.id" class="comment-item">
											<img :src="item1.head_photo" alt="" />
											<div class="c-i-right">
												<div class="com-top">
													<span>{{ item1.user_name }}</span>
													<b>回复</b>
													<span>{{ item1.for_name }}</span>
												</div>
												<p v-html="item1.content"></p>
												<div class="bottom">
													<span class="time">{{ moment(item1.create_date).format('YYYY-MM-DD HH:mm:ss') }}</span>
													<p>
														<span class="reply" @click="toComment(index, item, item1)">
															<i class="el-icon-chat-round"></i>
															回复
														</span>
														<span class="like" :class="commentLikeIdList.includes(item1.id) ? 'on' : ''" @click="commentLike(item1)">
															<i class="el-icon-thumb"></i>
															点赞{{ item1.like || 0 }}
														</span>
													</p>
												</div>
											</div>
										</div>
										<p class="more" v-if="item.comment_list">
											<span v-if="item.comment_list.length < item.comment_count" @click="getMoreComment(item)">
												展开更多
												<i class="el-icon-arrow-down"></i>
											</span>
											<span v-else @click="item.commentShow = false">
												收起
												<i class="el-icon-arrow-up"></i>
											</span>
										</p>
									</div>
									<!-- 评论文本框 -->
									<div class="reply-box" v-if="showReplyIndex == index">
										<p>回复@{{ commentInfo.for_name }}</p>
										<CommentEdit v-model="commentInfo.content" :maxCount="100" :height="100" @submit="submitComment"></CommentEdit>
									</div>
								</div>
							</div>
						</div>
					</div>
				</el-main>
			</el-container>
		</el-container>
		<div class="msg-footer">
			<el-pagination
				background
				@current-change="handleCurrentChange"
				:current-page.sync="currentPage"
				:page-size="pageSize"
				layout="prev, pager, next, jumper"
				:total="total"
			></el-pagination>
		</div>
	</div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import moment from 'moment';
import CommentEdit from '@/components/CommentEdit';
export default {
	name: 'msg-page',
	components: {
		CommentEdit
	},
	data() {
		const item = {
			date: '2016-05-02',
			name: '王小虎',
			address: '上海市普陀区金沙江路 1518 弄'
		};
		return {
			showReplyIndex: -1,
			moment,
			tableData: Array(20).fill(item),
			msg: 'link',
			currentPage: 1,
			total: 0,
			pageSize: 10,
			tinymce: '',
			messageList: [],
			commentInfo: {}
		};
	},
	created() {
		this.getMsgList();
	},
	computed: {
		...mapState(['boxHeight', 'userInfo']),
		...mapGetters(['messageLikeIdList', 'commentLikeIdList']),
		maxPage() {
			return Math.ceil(this.total / this.pageSize);
		}
	},
	watch: {
		currentPage() {
			this.getMsgList();
		}
	},
	methods: {
		...mapMutations(['setUserInfo']),
		getMoreComment(item) {
			this.$http
				.get('/comment', {
					id: item.id,
					limit: 3,
					offset: item.comment_list.length
				})
				.then(res => {
					if (res.code === 200) {
						// this.$set(item,'comment_list',res.data.list)
						item.comment_list.push(...res.data.list);
					}
				});
		},
		getComment(item) {
			if (item.comment_count === 0) return;
			this.$set(item, 'commentShow', !item.commentShow);
			if (item.comment_list && item.comment_list.length) return;
			this.$http
				.get('/comment', {
					id: item.id,
					limit: 3
				})
				.then(res => {
					console.log(res);
					if (res.code === 200) {
						this.$set(item, 'comment_list', res.data.list);
					}
				});
		},
		getMsgList() {
			this.$http
				.get('/message', {
					limit: this.pageSize,
					page: this.currentPage
				})
				.then(res => {
					console.log(res);
					if (res.code == 200) {
						this.messageList = res.data.list;
						this.total = res.data.total;
					}
				});
		},
		handleCurrentChange(page) {
			this.currentPage = page;
		},
		toComment(index, item, item1) {
			this.commentInfo = {
				for_id: item1 ? item1.user_id : item.user_id,
				parent_id: item.id,
				user_id: this.$store.state.userInfo.id,
				user_name: this.$store.state.userInfo.real_name,
				for_name: item1 ? item1.real_name : item.real_name,
				type: 'message'
			};
			// this.$set(this.commentInfo,'for_name',item1?item1.real_name:item.real_name)
			this.showReplyIndex = index;
		},
		submitEvent(editor, bool) {
			if (!bool) {
				return;
			}
			this.$http
				.post('/message/add', {
					user_id: this.$store.state.userInfo.id,
					user_name: this.$store.state.userInfo.real_name,
					content: this.tinymce
				})
				.then(res => {
					if (res.code === 200) {
						this.tinymce = '';
						this.getMsgList();
						this.$message({
							message: '发表成功',
							type: 'success'
						});
					} else {
						this.$message({
							message: res.msg,
							type: 'warning'
						});
					}
				});
		},
		// 提交评论
		submitComment(e, bool) {
			if (!bool) {
				return;
			}
			this.$http.post('/comment/add', this.commentInfo).then(res => {
				if (res.code === 200) {
					this.messageList[this.showReplyIndex].comment_list
						? this.messageList[this.showReplyIndex].comment_list.push(res.list[0])
						: this.$set(this.messageList[this.showReplyIndex], 'comment_list', res.list);

					this.messageList[this.showReplyIndex].comment_count++;
					this.messageList[this.showReplyIndex].commentShow = true;
					this.commentInfo.content = '';
					this.$message({
						message: '发表成功',
						type: 'success'
					});
				} else {
					this.$message({
						message: res.msg,
						type: 'warning'
					});
				}
			});
		},
		// 留言点赞
		messageLike(item) {
			console.log(item);
			let id = item.id
			console.log(id);
			let arr = [];
			let bool = this.messageLikeIdList.includes(id);
			if (bool) {
				console.log('取消点赞');
				item.like--
				arr = this.messageLikeIdList.filter(t => t != id);
			} else {
				item.like = item.like?item.like+1:1
				arr = [id].concat(this.messageLikeIdList);
				console.log('message点赞',this.messageLikeIdList);
			}
			console.log(arr);
			let obj = Object.assign({}, this.userInfo);
			let arrSt = JSON.stringify(arr);
			obj.message_like_id = arrSt;
			this.setUserInfo(obj);
			this.$http.post('/message/like', {
				type: bool ? 0 : 1,
				id,
				message_like_id: arrSt,
				user_id:this.userInfo.id
			}).then(res => {
				console.log(res);
			});
		},
		// 评论点赞
		commentLike(item) {
			console.log(item);
			let id = item.id
			console.log(id);
			let arr = [];
			let bool = this.commentLikeIdList.includes(id);
			if (bool) {
				console.log('取消点赞');
				item.like--
				arr = this.commentLikeIdList.filter(t => t != id);
			} else {
				item.like = item.like?item.like+1:1
				arr = [id].concat(this.commentLikeIdList);
				console.log('comment点赞',this.commentLikeIdList);
			}
			console.log(arr);
			let obj = Object.assign({}, this.userInfo);
			let arrSt = JSON.stringify(arr);
			obj.comment_like_id = arrSt;
			this.setUserInfo(obj);
			this.$http.post('/comment/like', {
				type: bool ? 0 : 1,
				id,
				comment_like_id: arrSt,
				user_id:this.userInfo.id
			}).then(res => {
				console.log(res);
			});
		}
	}
};
</script>
<style scoped lang="less">
.msg-page {
	.title {
		color: #3f9cfc;
		border-bottom: 1px solid #3f9cfc;
		display: flex;
		align-items: flex-end;
		h2 {
			font-weight: normal;
			font-size: 18px;
			margin-right: 50px;
		}
		h3 {
			font-weight: normal;
			font-size: 14px;
		}
	}
	.mes-box {
		.msg-send {
			button {
				margin-top: 5px;
			}
		}
		.msg-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			background-color: rgba(64, 157, 253, 0.2);
			line-height: 2;
			border-radius: 5px;
			padding: 0 10px;
			margin: 5px 0;
		}
		.msg-content {
			.msg-c-item {
				display: flex;
				padding: 10px;
				border-bottom: 1px solid @g-color;
				img {
					width: 50px;
					height: 50px;
					border-radius: 8px;
				}
				.msg-c-main {
					flex-grow: 1;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					min-height: 100px;
					.top {
						padding: 0 10px;
						div {
							.name {
								color: @g-color;
							}
							.floor {
								color: #aaa;
								font-size: 12px;
								margin-left: 10px;
							}
						}
						p {
							font-size: 16px;
							padding: 10px;
						}
					}
					.bottom {
						display: flex;
						justify-content: space-between;
						.time {
							font-size: 12px;
							color: #aaa;
							text-indent: 20px;
						}
						p {
							span {
								border: 1px solid @g-color;
								font-size: 12px;
								color: @g-color;
								border-radius: 5px;
								padding: 2px 5px;
								margin: 0 5px;
								cursor: pointer;
							}
							.like {
								&.on {
									background-color: @g-color;
									color: #fff;
								}
							}
						}
					}
					// 评论区域
					.comment {
						margin-top: 5px;
						.comment-item {
							display: flex;
							background-color: rgba(64, 157, 253, 0.1);
							padding: 5px;
							margin-bottom: 5px;
							img {
								width: 30px;
								height: 30px;
								border-radius: 5px;
							}
							.c-i-right {
								flex-grow: 1;
								margin-left: 5px;
								.com-top {
									padding: 5px;
									display: flex;
									align-items: center;
									span {
										color: @g-color;
										font-size: 14px;
									}
									b {
										color: #ccc;
										font-size: 12px;
										margin: 0 5px;
									}
								}
								p {
									font-size: 12px;
									padding: 10px;
								}
								.bottom {
									display: flex;
									justify-content: space-between;
									.time {
										font-size: 12px;
										color: #aaa;
										text-indent: 20px;
									}
									p {
										span {
											border: 1px solid @g-color;
											font-size: 12px;
											color: @g-color;
											border-radius: 5px;
											padding: 2px 5px;
											margin: 0 5px;
											cursor: pointer;
										}
										.like {
											&.on {
												background-color: @g-color;
												color: #fff;
											}
										}
									}
								}
							}
						}
					}
					.more {
						cursor: pointer;
						font-size: 12px;
						color: #ccc;
						span {
							&:hover {
								color: @g-color;
							}
						}
					}
					.reply-box {
						p {
							font-size: 12px;
							line-height: 1.5;
							color: #ccc;
						}
						margin-top: 10px;
					}
				}
			}
		}
	}
}
</style>
