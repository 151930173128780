import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
// import createPersistedState from 'vuex-persistedstate'
export default new Vuex.Store({
	state: {
		boxHeight: document.documentElement.clientHeight < 690 ? 690 : document.documentElement.clientHeight,
		token:'',
		userInfo:{},
	},
	getters: {
		topicPassNum(s){
			return JSON.parse(s.userInfo.passtopic||"[]")
		},
		messageLikeIdList(s){
			return JSON.parse(s.userInfo.message_like_id||"[]")
		},
		commentLikeIdList(s){
			return JSON.parse(s.userInfo.comment_like_id||"[]")
		}
	},
	mutations: {
		updataBoxHeight(state, num) {
			state.boxHeight = num
		},
		login(state,data){
			state.token = data.token
			state.userInfo = data.user
		},
		setUserInfo(state,user){
			
			state.userInfo = user
		}
	},
	actions: {}
	// plugins: [createPersistedState({
	// 	storage:window.sessionStorage
	// })]
})