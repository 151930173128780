import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const _import = file => require('@/views/' + file + '.vue').default

import arr from './config.js'
let routes = [{
	path: '/',
	redirect: '/home'
},{
	path:'/login',
	component:_import('LoginView')
}]
for (let i = 0; i < arr.length; i++) {
	let obj = arr[i]
	let route = {
		path: obj.path,
		component: null,
		name: obj.name,
		meta: Object.assign(obj.meta)
	}
	try {
		route['component'] = _import(obj.url) || null
	} catch (e) {
		console.log(e);
	}
	routes.push(route)
}


const router = new VueRouter({
	routes
})

router.afterEach(to => {
  document.title = '这是我的世界-'+to.meta.title
})

export default router
