<template>
	<el-dialog title="修改密码" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close='onclose'>
		<el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" :label-width="formLabelWidth" class="demo-ruleForm">
			<el-form-item label="用户名" prop="age">{{ ruleForm.user_name }}</el-form-item>
			<el-form-item label="原密码" prop="pass"><el-input type="password" v-model="ruleForm.password" autocomplete="off"></el-input></el-form-item>
			<el-form-item label="密码" prop="pass"><el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input></el-form-item>
			<el-form-item label="确认密码" prop="checkPass"><el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input></el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
				<el-button @click="resetForm('ruleForm')">重置</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
import md5 from 'js-md5';
import { mapState, mapMutations } from 'vuex';
export default {
	name: 'update-password',
	data() {
		var validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				if (this.ruleForm.checkPass !== '') {
					this.$refs.ruleForm.validateField('checkPass');
				}
				callback();
			}
		};
		var validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.ruleForm.pass) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			formLabelWidth: '120px',
			ruleForm: {},
			rules: {
				pass: [{ validator: validatePass, trigger: 'blur' }],
				checkPass: [{ validator: validatePass2, trigger: 'blur' }]
			},
			dialogFormVisible: false
		};
	},
	computed: {
		...mapState(['userInfo'])
	},
	methods: {
		...mapMutations(['setLoginMsg', 'login']),
		open() {
			this.dialogFormVisible = true;
			this.ruleForm.user_name = this.userInfo.user_name;
		},
		onclose(){
			this.$emit('onclose')
		},
		submitForm() {
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					this.$http
						.post('/password', {
							user_name: this.ruleForm.user_name,
							password: this.ruleForm.password,
							pass: this.ruleForm.pass,
							passcode: md5(this.ruleForm.pass)
						})
						.then(res => {
							
							if (res.code === 200) {
								this.$message({
									message: res.msg,
									type: 'success'
								});
								this.dialogFormVisible = false
								this.login({})
								this.setLoginMsg('')
							}
						});
				} else {
					
					return false;
				}
			});
		}
	}
};
</script>

<style scoped lang="less"></style>
