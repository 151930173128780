<template>
	<el-dialog append-to-body fullscreen class="dialog" title="面试测试" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close="onclose">
		<ul>
			<li v-for="item in topicList" :key="item.id" class="item">
				<div class="top">
					<div class="left">
						<p class="title">{{ item.title }}</p>
						<el-input v-model="item.answer" :disabled="isEnd" type="textarea" placeholder="答案" class="ipt"></el-input>
					</div>
					<el-result
						v-if="isEnd"
						class="result"
						:icon="item.score >= 80 ? 'success' : 'error'"
						:title="item.score >= 80 ? '通过' : '未通过'"
						:subTitle="item.score || 0 + '分'"
						:style="{ color: item.score >= 80 ? 'green' : 'red' }"
					></el-result>
				</div>
				<div class="keyword" v-if="isEnd">
					<el-tag v-for="s in item.keyword.split(',')" :key="s">{{ s }}</el-tag>
				</div>
				<div class="result" v-if="isEnd">答案:{{ item.result }}</div>
			</li>
		</ul>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onCancel">取 消</el-button>
			<el-button type="primary" @click="onSubmit">确 定</el-button>
		</span>
	</el-dialog>
</template>
<!-- score -->
<script>
import { getScroe } from '@/utils';
import {mapState,mapMutations} from 'vuex'
export default {
	name: 'exam-dialog',
	data() {
		return {
			dialogFormVisible: false,
			topicList: [],
			isEnd: false
		};
	},
	computed:{
		...mapState(['userInfo'])
	},
	methods: {
		...mapMutations(['setUserInfo']),
		open(form) {
			this.dialogFormVisible = true;
			this.getList(form);
		},
		submitForm() {},
		onclose() {
			this.$emit('close');
		},
		getList({ type, limit, fliterRight,random }) {
			let params = {
				type,
				limit,
				random
			};
			if (fliterRight) {
				params.passtopic = JSON.parse(this.$store.state.userInfo.passtopic||"[]")
			}
			this.$http.get('/topic', params).then(res => {
				if (res.code === 200) {
					this.topicList = res.data.list;
				}
			});
		},
		onSubmit() {
			this.$confirm('是否确认交卷?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.isEnd = true;
					this.topicList = this.topicList.map(item => {
						item.score = getScroe(item.result, item.answer, item.keyword, item.keywordRadio);
						item.isWrong = item.score < 80;
						return item;
					});
					let passArr = this.topicList.filter(item => !item.isWrong).map(item => item.id);
					this.$http
						.post('/users/pass', {
							id: this.userInfo.id,
							passtopic: passArr
						})
						.then(res => {
							if(res.code === 200){
								let obj = Object.assign({},this.userInfo)
								res.list[0]&&(obj.passtopic = res.list[0].passtopic)
								this.setUserInfo(obj)
							}
						});
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		},
		onCancel() {
			this.$confirm('是否确认退出考试?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					this.dialogFormVisible = false;
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
		}
	}
};
</script>

<style scoped lang="less">
.dialog {
	height: 100vh;
	.item {
		padding: 10px;
		border: 1px solid #ccc;
		margin: 5px 0;
		border-radius: 5px;
		.top {
			display: flex;
			justify-content: space-between;
			.left {
				flex-grow: 1;
				p {
					padding: 5px;
					margin: 5px;
					background-color: #55aaff;
					color: #fff;
					border-radius: 5px;
				}
				margin-right: 20px;
			}
			/deep/ .el-result {
				padding: 0;
				.el-result__icon svg {
					width: 32px;
					height: 30px;
				}
				.el-result__title {
					margin: 0;
					p {
						font-size: 12px;
					}
				}
				.el-result__subtitle p {
					font-size: 16px;
					color: inherit;
				}
			}
		}
		.keyword {
			border-radius: 5px;
			margin: 5px;
			padding: 5px;
		}
		.result {
			background-color: #eee;
			border-radius: 5px;
			margin: 5px;
			padding: 5px;
		}
	}
}
</style>
