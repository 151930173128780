<template>
	<el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-click-modal="false" @close="onclose">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
			<el-form-item label="用户名" :label-width="formLabelWidth" prop="role_name">
				<el-input :disabled="disabled" v-model="ruleForm.role_name" placeholder="用户名" />
			</el-form-item>
			<el-form-item label="用户代码" :label-width="formLabelWidth" prop="user_code">
				<el-input :disabled="disabled" v-model="ruleForm.user_code" placeholder="账号"></el-input>
			</el-form-item>
			<el-form-item label="账号" :label-width="formLabelWidth" prop="user_name">
				<el-input :disabled="disabled" v-model="ruleForm.user_name" placeholder="账号"></el-input>
			</el-form-item>
			<el-form-item label="密码" :label-width="formLabelWidth" prop="password" v-if='!disabled'><el-input v-model="ruleForm.password" placeholder="密码"></el-input></el-form-item>
			<el-form-item label="手机" :label-width="formLabelWidth" prop="phone"><el-input v-model="ruleForm.phone" placeholder="手机"></el-input></el-form-item>
			<el-form-item label="邮箱" :label-width="formLabelWidth" prop="email"><el-input v-model="ruleForm.email" placeholder="邮箱"></el-input></el-form-item>
			<el-form-item label="qq" :label-width="formLabelWidth" prop="qq"><el-input v-model="ruleForm.qq" placeholder="QQ"></el-input></el-form-item>
			<el-form-item label="真实姓名" :label-width="formLabelWidth" prop="real_name"><el-input v-model="ruleForm.real_name" placeholder="真实姓名"></el-input></el-form-item>
			<!-- <el-form-item label="note" :label-width="formLabelWidth" prop="note"><el-input v-model="ruleForm.note" placeholder="备注"></el-input></el-form-item> -->
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
			<el-button @click="resetForm('ruleForm')">重置</el-button>
		</div>
	</el-dialog>
</template>

<script>
import md5 from 'js-md5';
export default {
	name: 'register-page',
	data() {
		return {
			formLabelWidth: '120px',
			ruleForm: {
				role_id: 3
			},
			rules: {
				password: [
					{ required: true, message: '请输入密码', trigger: 'blur' },
					{ pattern: /^[a-zA-Z]\w{7,15}$/, message: '字母开头,由数字字母下划线组成,长度8-16位', trigger: 'blur' }
				],
				phone: [{ required: true, message: '请输入手机号码', trigger: 'blur' }, { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }],
				email: [
					{ required: true, message: '请输入邮箱', trigger: 'blur' },
					{ pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/, message: '请输入正确的手机号', trigger: 'blur' }
				],
				nickname: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
				role_id: [{ required: true, message: '请选择角色', trigger: 'blur' }],
				real_name: [{ required: true, message: '请输入真实姓名', trigger: 'blur' }],
				role_name: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
				user_name: [
					{ required: true, message: '请输入账号', trigger: 'blur' },
					{ pattern: /^[a-zA-Z]\w{7,15}$/, message: '字母开头,由数字字母下划线组成,长度8-16位', trigger: 'blur' }
				],
				qq: [{ required: true, message: '请输入qq', trigger: 'blur' }, { pattern: /^[1-9][0-9]{4,9}$/gim, message: '请输入正确的QQ号', trigger: 'blur' }]
			},
			dialogFormVisible: false,
			title: '注册用户',
			disabled: false
		};
	},
	methods: {
		open(userInfo) {
			
			if (userInfo) {
				this.title = '修改用户信息';
				this.disabled = true;
				this.ruleForm = Object.assign({}, userInfo);
			}
			this.dialogFormVisible = true;
		},
		submitForm() {
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					// update
					this.ruleForm.passcode = md5(this.ruleForm.password);
					this.$http.post(this.disabled ? '/users/update' : '/register', this.ruleForm).then(res => {
						
						if (res.code === 200) {
							this.$message({
								message: this.title + '成功',
								type: 'success'
							});
							this.dialogFormVisible = false;
							if (this.disabled) {
								this.$store.commit('setUserInfo', res.userInfo);
							}
						}
					});
				} else {
					
					return false;
				}
			});
		},
		onclose() {
			this.$emit('onclose');
		}
	}
};
</script>

<style scoped lang="less"></style>
