import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// 引入css
import '@/assets/less/index.less'

// 引入element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入 axios  httpRequest
import httpRequest from '@/utils/httpRequest'
Vue.prototype.$http = httpRequest


import * as buffer from "buffer";
if (typeof window.global === "undefined") {
	window.global = window;
}
if (typeof window.Buffer === "undefined") {
	window.Buffer = buffer.Buffer;
}

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')