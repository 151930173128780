<!-- app -->
<template>
	<div id="app">
		<MainView></MainView>
	</div>
</template>
<script>
	import MainView from '@/views/MainView'
	export default {
		name: 'app-page',
		components: {
			MainView
		},
		created() {
			

		}
	}
</script>
<style scoped>
</style>
