<template>
	<el-dialog title="登录" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" :label-width="formLabelWidth" class="demo-ruleForm">
			<el-form-item label="账号" prop="user_name"><el-input v-model="ruleForm.user_name" clearable></el-input></el-form-item>
			<el-form-item label="密码" prop="password"><el-input v-model="ruleForm.password" clearable type="password" show-password></el-input></el-form-item>
			<el-form-item>
				<el-checkbox v-model="ruleForm.remember">记住密码</el-checkbox>
				<el-checkbox v-model="ruleForm.isAuto">自动登录</el-checkbox>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm()">登录</el-button>
				<el-button @click="dialogFormVisible = false">取消</el-button>
			</el-form-item>
		</el-form>
	</el-dialog>
</template>

<script>
import md5 from 'js-md5';
import { mapState, mapMutations } from 'vuex';
import {encryptBy,decryptBy,Cookies} from '@/utils'
export default {
	name: 'logon-page',
	data() {
		return {
			formLabelWidth: '120px',
			ruleForm: {},
			rules: {
				user_name: [{ required: true, message: '请输入账号', trigger: 'blur' }],
				password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
			},
			dialogFormVisible: false
		};
	},
	created() {
		
		let loginMsg = JSON.parse(decryptBy(Cookies.getItem('loginMsg'))||"{}")
		
		if(loginMsg.remember){
			this.ruleForm = loginMsg
		}
	},
	computed: {
		...mapState(['loginMsg'])
	},
	methods: {
		...mapMutations(['setLoginMsg', 'login']),
		open() {
			this.dialogFormVisible = true;
		},
		submitForm() {
			this.$refs['ruleForm'].validate(valid => {
				if (valid) {
					this.$http
						.post('/login', {
							user_name: this.ruleForm.user_name,
							password: md5(this.ruleForm.password)
						})
						.then(res => {
							
							if (res.code === 200) {
								Cookies.setItem('loginMsg',encryptBy(JSON.stringify(this.ruleForm)),30)
								this.login(res.data);
								this.dialogFormVisible = false
								this.$message({
									type: 'success',
									message:'登录成功'
								});
							}else{
								this.$message({
									type: 'error',
									message:res.msg
								});
							}
						});
				} else {
					
					return false;
				}
			});
		}
	}
};
</script>

<style scoped lang="less"></style>
