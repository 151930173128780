<template>
	<div class="topic">
		<el-container class="container" v-if="$store.state.userInfo && $store.state.userInfo.role_id && $store.state.userInfo.role_id <= 2">
			<el-header class="header">
				<div class="left">
					<el-select v-model="select" placeholder="查询面试题" clearable @change="currentPage = 1">
						<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
					<el-button icon="el-icon-search" @click="getList"></el-button>
				</div>
				<div class="right">
					<el-progress :percentage="percentage" :format="format" class="progress"></el-progress>
					<el-select v-model="form.type" placeholder="题目类型(默认全部)" clearable>
						<el-option v-for="item in typeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
					<!-- <el-select v-model="form.limit" placeholder="题目数量(默认10条)" clearable>
						<el-option :label="10" :value="10"></el-option>
						<el-option :label="20" :value="20"></el-option>
						<el-option :label="30" :value="30"></el-option>
						<el-option :label="40" :value="40"></el-option>
					</el-select> -->
					<el-input-number v-model="form.limit" :step="5" controls-position="right" :min="10" placeholder="题目数量(最少10条)"></el-input-number>
					<span>
						过滤对题:
						<el-switch v-model="form.fliterRight" />
					</span>
					<span>
						随机取题
						<el-switch v-model="form.random" />
					</span>
					<el-button type="primary" size="small" @click="startExam" style="margin-left: 20px;">开始考试</el-button>
				</div>
			</el-header>
			<el-main>
				<el-collapse v-if="topicList && topicList.length > 0">
					<el-collapse-item :name="item.id" v-for="item in topicList" :key="item.id">
						<template slot="title">
							<div class="title">
								<p>{{ item.title }}</p>
								<el-tag type="warning" effect="dark" size="mini">{{ item.importance }}</el-tag>
							</div>
						</template>
						<div class="keyword">
							关键字:
							<el-tag :key="tag" v-for="tag in item.keyword.split(',')" style="margin: 0 10px;">{{ tag }}</el-tag>
						</div>
						<div class="res">{{ item.result }}</div>
					</el-collapse-item>
				</el-collapse>
				<el-empty description="暂无数据" v-else class="empty"></el-empty>
			</el-main>
			<el-pagination @current-change="handleCurrentChange" :current-page="currentPage" layout=" prev, pager, next" :total="total"></el-pagination>
			<ExamDialog ref="examDialog" @close="onClose" v-if="dialogShow"></ExamDialog>
		</el-container>
		<el-empty description="您未登录或者权限不够" v-else class="empty"></el-empty>
	</div>
</template>

<script>
import ExamDialog from './ExamDialog';
import {mapGetters} from 'vuex'
export default {
	name: 'topic-page',
	components: {
		ExamDialog
	},
	data() {
		return {
			select: '',
			typeList: [],
			topicList: [],
			currentPage: 1,
			total: 0,
			allTotal: 0,
			form: {
				limit: 10
			},
			dialogShow: false,
			percentage:0
		};
	},
	computed:{
		...mapGetters(['topicPassNum'])
	},
	created() {
		this.getTypeList();
		this.getList();
	},
	methods: {
		getTypeList() {
			this.$http.get('/type').then(res => {
				if (res.code === 200) {
					this.typeList = res.list;
				}
			});
		},
		getList() {
			this.$http
				.get('/topic', {
					type: this.select,
					page: this.currentPage
				})
				.then(res => {
					if (res.code === 200) {
						this.topicList = res.data.list;
						this.total = res.data.total;
						this.allTotal = res.data.allTotal;
						this.percentage = Math.floor(100*this.topicPassNum.length/res.data.allTotal)
					}
				});
		},
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getList();
		},
		startExam() {
			this.dialogShow = true;
			this.$nextTick(() => {
				this.$refs.examDialog.open(this.form);
			});
		},
		onClose() {
			this.dialogShow = false;
			this.percentage = Math.floor(100*this.topicPassNum.length/this.allTotal)
		},
		format(percentage) {
			return `完成度${percentage}%`
		}
	}
};
</script>

<style scoped lang="less">
.topic .container {
	height: calc(100vh - 200px);
	.title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		p {
			margin-right: 30px;
		}
	}
	.res {
		background-color: rgba(64, 157, 253, 0.1);
		padding: 20px;
		margin-top: 20px;
	}
	.header {
		display: flex;
		justify-content: space-between;
		// .left {
		// 	background-color: pink;
		// }
		// .right {
		// 	background-color: #0f0;
		// }
		.progress{
			display: flex;
			align-items: center;
			/deep/.el-progress__text{
				flex-shrink: 0;
				font-size: 12px;
				order: -1;
				color: #67C23A !important;
			}
		}
	}
}
</style>
