<template>
	<div>
		<Editor class="editor-box" :init="init" :value="value" @input="onInput" :api-key="'tqjulhs9rxdkgxusdcthfswiy9a555f96amcz46oxxxrvla8'"></Editor>
	</div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';
export default {
	name: 'edit-page',
	props: {
		value: {
			type: String,
			default: ''
		},
		maxCount: {
			type: Number,
			default: Infinity
		},
		placeholder: {
			type: [String, Number],
			default: ''
		},
		resize: {
			type: Boolean,
			default: false
		},
		height:{
			type: [String, Number],
			default: 140
		}
	},
	components: {
		Editor
	},
	data() {
		return {
			editor: null,
			isOk:true,
			init: {
				width: '100%',
				toolbar_location: 'bottom',
				height: this.height,
				encoding: 'xml',
				// placeholder: this.placeholder,
				resize: this.resize,
				statusbar: false,
				plugins: 'emoticons wordcount', // tinymce自带表情
				toolbar: 'emoticons myCustomToolbarButton',
				menubar: false,
				branding: false,
				language: 'zh_CN', //调用放在langs文件夹内的语言包
				setup: e => {
					this.editor = e;
					e.ui.registry.addButton('myCustomToolbarButton', {
						text: '<button class="submit"><i class="el-icon-edit-outline"></i>发表</button>',
						onAction: ()=> {
							this.$emit('submit',e,this.isOk)
						}
					});
				},
				init_instance_callback: editor => {
					let msgIsShow = false;
					editor.on('input', () => {
						let words = editor.contentDocument.body.innerText.length;
						if (words > this.maxCount) {
							msgIsShow ||
								this.$message({
									message: `字数超过限制最多${this.maxCount}字`,
									type: 'warning',
									onClose() {
										msgIsShow = false;
									}
								});
								this.isOk = false
							msgIsShow = true;
							this.$emit('overword', words);
						}else{
							this.isOk = true
						}
					});
				}
			}
		};
	},
	methods: {
		onInput(e) {
			this.$emit('input', e);
		}
	}
};

// init: {
// 			width: '100%',
// 			statusbar: false,
// 			height: '140px',
// 			encoding: 'xml',
// 			elementpath: false,
// 			plugins: 'emoticons', // tinymce自带表情
// 			toolbar: 'emoticons | bold  italic underline forecolor backcolor',
// 			menubar: false,
// 			branding: false,
// 			language: 'zh_CN', //调用放在langs文件夹内的语言包
// 			setup: e => {
// 				this.editor = e;
// 			}
// 		},
</script>

<style scoped lang="less">
/deep/.tox-tbtn {
	&:hover {
		background-color: transparent !important;
	}
	.submit {
		background-color: #f00;
		padding: 4px 8px;
		border-radius: 4px;
		background-color: @g-color;
		color: #fff;
		font-size: 12px;
		position: absolute;
		right: 10px;
		bottom: 6px;
		cursor: pointer;
		&:hover {
			background: #66b1ff;
			border-color: #66b1ff;
			color: #fff;
		}
	}
}

/deep/.tox-sidebar-wrap{
	border: 1px solid @g-color;
	border-radius: 5px;
	overflow: hidden;
}
/deep/.tox-tinymce {
	border:none;
	background-color:transparent;
	
}
/deep/.tox-toolbar-overlord{
	background-color:transparent !important;
	.tox-toolbar__primary {
		border:none !important;
		background-color:transparent !important;
		svg {
			color: @g-color;
			fill: currentColor !important;
		}
	}
}
</style>
