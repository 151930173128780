<!-- learn -->
<template>
	<div class="learn-page">
		<el-container v-if="$store.state.token" :style="{ height: boxHeight - 180 + 'px' }">
			<el-header style="height: auto;">
				<el-form :inline="true" :model="form" class="demo-form-inline">
					<el-form-item>
						<el-select v-model="form.type" clearable placeholder="学习种类">
							<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item><el-input v-model="form.key" placeholder="名称"></el-input></el-form-item>
					<el-form-item><el-button type="primary" icon="el-icon-search" @click="getDataList">搜索</el-button></el-form-item>
				</el-form>
			</el-header>
			<el-main v-loading="loading" element-loading-text="拼命加载中">
				<el-row>
					<el-col :span="8" v-for="(item, index) in dataList" :key="index">
						<el-card class="card">
							<div slot="header" class="clearfix">
								<span style="color: #333;font-size: 14px;">
									{{ item.sort }}.{{ item.name }}
									<el-tag size="mini">{{ options.find(t=>t.id===item.type)&&options.find(t=>t.id===item.type).name  }}</el-tag>
								</span>
								<el-button style="float: right; padding: 3px 0" type="text">查看详情</el-button>
							</div>
							<div class="card-box" style="height: 80px;">
								<div>
									<span>{{ item.description }}</span>
								</div>
								<div class="button">
									<el-dropdown @command="handleCommand" size="small">
										<el-button type="primary" size="mini" :disabled="!item.learn_more || item.learn_more.length == 0">
											知识拓展
											<i class="el-icon-arrow-down el-icon--right"></i>
										</el-button>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item class="dropdown-item" :command="item1.url" v-for="item1 in item.learn_more" :key="item1.id">
												<div
													style="max-width: 200px;
													text-align: center;
													white-space: nowrap;
													overflow: hidden;
													text-overflow: ellipsis;
												"
												>
													{{ item1.title }}
												</div>
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
									<el-dropdown @command="handleCommand" size="small" style="margin-left: 20px	;">
										<el-button type="primary" size="mini" :disabled="!item.case_list || item.case_list.length == 0">
											课后作业
											<i class="el-icon-arrow-down el-icon--right"></i>
										</el-button>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item :command="item1.url" v-for="item1 in item.case_list" :key="item1.id" :disabled="!item1.url">
												<div
													style="max-width: 200px;
													text-align: center;
													white-space: nowrap;
													overflow: hidden;
													text-overflow: ellipsis;
												"
													class="dropdown-item"
												>
													{{ item1.title }}
												</div>
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>
						</el-card>
					</el-col>
				</el-row>
			</el-main>
		</el-container>
		<el-empty description="请先登录" v-else class="empty"></el-empty>
	</div>
</template>
<script>
import { mapState } from 'vuex';

export default {
	name: 'learn-page',
	computed: {
		...mapState(['boxHeight'])
	},
	data() {
		return {
			loading:false,
			form: {
				type:1
			},
			dataList: [],
			options: [],
			value: ''
		};
	},
	mounted() {
		// this.dataList = window.zyConfig.learnDetail.dataList.sort((a, b) => a.sort - b.sort);1
		this.getDataList()
		this.getTypeList()
	},
	methods: {
		handleCommand(command) {
			
			if (command.startsWith('http')) {
				window.open(command);
			} else {
				// let st = command.replace('zy', 'views').replaceAll('/', '-');
				window.open('http://image.hnclove.com/zy/#' + command);
			}
		},
		getTypeList(){
			this.$http.get('/type').then(res => {
				
				if (res.code === 200) {
					this.options = res.list
				}
			})
		},
		getDataList(){
			
			this.loading = true
			this.$http.get('/learn',{
				page:1,
				limit:99999,
				type:this.form.type,
				key:this.form.key
			}).then(res => {
				this.loading = false
				
				if (res.code === 200) {
					this.dataList = res.data.list
				}
			}).catch(()=>{
				this.loading = false
			})
		}
	}
};
</script>
<style scoped lang="less">
.learn-page {
	.card {
		margin: 0 10px 10px 10px;

		.card-box {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			.button {
				display: flex;
				justify-content: flex-end;
			}

			.dropdown-item {
				width: 200px;
				background-color: red;
			}
		}
	}
}
</style>
