<!-- Technology -->
<template>
	<div class="Technology-page">
		<el-container :style="{ height: boxHeight - 220 + 'px' }">
			<el-header style="height: auto;">
				<el-form :inline="true" :model="form" class="demo-form-inline">
					<el-form-item>
						<el-select v-model="form.link_type" clearable placeholder="链接种类">
							<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="form.type" clearable placeholder="语言种类">
							<el-option v-for="item in options1" :key="item.id" :label="item.name" :value="item.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item><el-input v-model="form.key" placeholder="关键字"></el-input></el-form-item>
					<el-form-item><el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button></el-form-item>
				</el-form>
			</el-header>
			<el-main>
				<el-row :gutter="20" v-if="dataList.length>0">
					<el-col :span="8" v-for="item in dataList" :key="item.id" style="margin-bottom: 20px;">
						<el-card class="card">
							<div class="header">
								<div class="title">
									<img :style="{ width: item.logoWidth || '30px' }" class="img" :src="item.logo_url || '/favicon.ico'" alt="" />
									<p>{{ item.title }}</p>
								</div>
								<el-link @click="checkDetail(item.url)" target="_blank" type="success">查看详情</el-link>
							</div>
							<div class="card-box">
								<el-tooltip class="item" placement="top" effect="light">
									<div slot="content" style="width: 200px;">{{ item.description }}</div>
									<p>{{ item.description }}</p>
								</el-tooltip>
							</div>
						</el-card>
					</el-col>
				</el-row>
				<el-empty :image-size="200" v-else></el-empty>
			</el-main>
		</el-container>
		<el-pagination background layout="prev, pager, next" :total="total" style="margin-top: 10px;" @current-change="handleCurrentChange"></el-pagination>
	</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
	name: 'Technology-page',
	computed: {
		...mapState(['boxHeight'])
	},
	data() {
		return {
			form: {},
			options: [],
			options1:[],
			dataList: [],
			page:1,
			total:0
		};
	},
	created() {
		this.getLinkList();
		this.getOptions();
	},
	methods: {
		getLinkList() {
			this.$http.get('/link',{
				page:this.page,
				limit:9,
				link_type:this.form.link_type,
				type:this.form.type,
				key:this.form.key
			}).then(res => {
				
				if (res.code === 200) {
					this.dataList = res.data.list;
					this.total = res.data.total
				}
			});
		},
		getOptions() {
			this.$http.get('/link/type').then(res => {
				
				if (res.code === 200) {
					this.options = res.list;
				}
			});
			this.$http.get('/type').then(res => {
				
				if (res.code === 200) {
					this.options1 = res.list;
				}
			});
		},
		onSearch() {
			
			this.page = 1
			this.getLinkList()
		},
		checkDetail(url) {
			window.open(url);
		},
		handleCurrentChange(page){
			this.page = page
			
			this.getLinkList()
		}
	}
};
</script>
<style scoped lang="less">
.header {
	border-bottom: 1px solid #ccc;
	padding-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.title {
		display: flex;
		align-items: center;

		.img {
			height: 30px;
			margin-right: 10px;
			object-fit: cover;
			object-position: left;
		}

		p {
			max-width: 180px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
	}
}

.card {
	.title-text {
		max-width: 150px;
		background-color: red;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}

.card-box {
	height: 70px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	p {
		padding-top: 10px;
		overflow: hidden; //超出的文本隐藏
		display: -webkit-box;
		-webkit-line-clamp: 2; // 超出多少行
		-webkit-box-orient: vertical;
	}

	div {
		text-align: right;
	}
}
</style>
