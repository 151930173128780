<!-- main -->
<template>
	<div class="main-page">
		<div class="main-bg">
			<p v-for="item in 10" :key="item"></p>
			<p class="row"></p>
		</div>
		<div class="main-box">
			<nav>
				<div class="nav">
					<router-link v-for="(item, index) in routes" :key="index" :to="{ name: item.name }">{{ item.meta.title }}</router-link>
				</div>
				<div class="border" :style="{ width: bWidth, left: bLeft }"></div>
				<div class="login">
					<el-dropdown size="mini" split-button v-if="token" @command="handleCommand" class="dropdown" @click="handleClick">
						<el-avatar shape="square" size="small" style="height: 26px;" :src="userInfo.head_photo || '/favicon.ico'" class="head"></el-avatar>
						{{ userInfo.real_name }}
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item command="1">修改密码</el-dropdown-item>
							<el-dropdown-item command="3">修改信息</el-dropdown-item>
							<el-dropdown-item command="2">退出登录</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
					<template v-else>
						<el-button size="mini" @click="openLogin">登录</el-button>
						<el-button size="mini" @click="register()">注册</el-button>
					</template>
				</div>
			</nav>
			<el-card class="card" :style="{ height: boxHeight }">
				<div class="box">
					<keep-alive><router-view /></keep-alive>
				</div>
			</el-card>
		</div>
		<LoginView v-if="loginShow" @onclose="onClose" ref="loginview"></LoginView>
		<UpdatePassword v-if="updateShow" @onclose="onClose1" ref="updataView"></UpdatePassword>
		<RegisterView v-if="registerShow" @onclose="onClose2" ref="registerView"></RegisterView>
		<HeadDialog v-if="headShow" @onclose="headColse" ref="headView"></HeadDialog>
		<p class="footer"><a href="https://beian.miit.gov.cn/" target = '_blank' style="color: #fff;">湘ICP备19018386号</a><span>联系我们</span></p>
	</div>
</template>
<script>
import arr from '@/router/config.js';
import { mapState, mapMutations } from 'vuex';
import LoginView from './LoginView.vue';
import UpdatePassword from './UpdatePassword.vue';
import HeadDialog from './HeadDialog.vue';
import RegisterView from './RegisterView.vue';
import { decryptBy, Cookies } from '@/utils';
import md5 from 'js-md5';
export default {
	name: 'main-page',
	components: {
		LoginView,
		UpdatePassword,
		RegisterView,
		HeadDialog
	},
	data() {
		return {
			routes: arr,
			index: 0,
			bLeft: 0,
			bWidth: 0,
			loginShow: false,
			updateShow: false,
			headShow: false,
			registerShow: false
		};
	},
	computed: {
		...mapState(['token', 'userInfo']),
		boxHeight: {
			get() {
				return this.$store.state.boxHeight - 130 + 'px';
			},
			set(val) {
				this.$store.commit('updataBoxHeight', val);
			}
		}
	},
	watch: {
		$route: {
			handler() {
				this.$nextTick(() => {
					this.bWidth = document.querySelector('.router-link-active').offsetWidth + 'px';
					this.bLeft = document.querySelector('.router-link-active').offsetLeft + 'px';
				});
			},
			// 深度观察监听
			immediate: true
		}
	},
	methods: {
		...mapMutations(['login']),
		openLogin() {
			this.loginShow = true;
			this.$nextTick(() => {
				this.$refs.loginview.open();
			});
		},
		register(userInfo) {
			this.registerShow = true;
			this.$nextTick(() => {
				this.$refs.registerView.open(userInfo);
			});
		},
		onClose() {
			this.loginShow = false;
		},
		onClose1() {
			this.updateShow = false;
		},
		onClose2() {
			this.registerShow = false;
		},
		headColse() {
			this.headShow = false;
		},
		handleCommand(c) {
			if (c == 1) {
				this.updateShow = true;
				this.$nextTick(() => {
					this.$refs.updataView.open();
				});
			}
			if (c == 2) {
				this.login({});
				Cookies.removeItem('loginMsg');
			}
			if (c == 3) {
				this.register(this.userInfo);
			}
		},
		handleClick() {
			this.headShow = true;
			this.$nextTick(() => {
				this.$refs.headView.open();
			});
		}
	},
	created() {
		window.addEventListener('resize', () => {
			this.boxHeight = document.documentElement.clientHeight < 600 ? 600 : document.documentElement.clientHeight;
		});
		this.$alert('此次更新了留言功能(有什么BUG或者建议可以给我留言)以及头像功能(可以再右上角点击头像更换头像)', '更新', {
			confirmButtonText: '确定',
		});
		// 自动登录
		let loginMsg = JSON.parse(decryptBy(Cookies.getItem('loginMsg')) || '{}');

		if (loginMsg.isAuto) {
			this.$http
				.post('/login', {
					user_name: loginMsg.user_name,
					password: md5(loginMsg.password)
				})
				.then(res => {
					if (res.code === 200) {
						this.login(res.data);
					}
				});
		}
	}
};
</script>
<style scoped lang="less">
.main-page {
	.footer{
		color:#eee;
		position: fixed;
		bottom: 0;
		text-align: center;
		width: 1200px;
		left: 0;
		right: 0;
		margin: auto;
		display: flex;
		justify-content: space-between;
	}
	background-color: @g-color;
	min-height: 100vh;
	position: relative;
	.card {
		.box {
			position: relative;
			z-index: 1;
		}
	}
	.main-box {
		width: 1200px;
		margin: 0 auto;
		nav {
			display: flex;
			align-items: center;
			justify-content: space-between;
			height: 80px;
			position: relative;
			.border {
				position: absolute;
				top: 30px;
				bottom: 0;
				height: 2px;
				margin: auto;
				background-color: #fff;
				transition: all 0.5s;
			}
			a {
				margin: 0 20px;
				padding: 5px 0;
				color: #fff;
			}
			.login {
				padding: 0 20px;
				display: flex;
				align-items: center;
				.dropdown {
					position: relative;
					/deep/ .el-button-group button:nth-child(1) {
						padding-left: 40px;
					}
					.head {
						position: absolute;
						left: 0;
						top: 0;
					}
				}
			}
		}
	}

	.main-bg {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: space-around;
		padding: 0 50px;
		p {
			width: 5px;
			height: 100%;
			background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
			box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.2);
			opacity: 0.5;
			&.row {
				background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2), rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2));
				width: 100%;
				height: 5px;
				position: absolute;
				top: 50vh;
				// transform: rotate(90deg);
			}
		}
	}
}
</style>
